@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(/static/media/Roboto-Regular.31012f9b.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(/static/media/Roboto-Bold.3f2cbde6.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto-Italic'), url(/static/media/Roboto-Italic.0d0bf30e.woff) format('woff');
}


@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'), url(/static/media/Roboto-Medium.e2d3fd03.woff) format('woff');
}

@font-face {
  font-family: 'icons';
  src:  url(/static/media/icons.6f1ffa56.eot);
  src:  url(/static/media/icons.6f1ffa56.eot#iefix) format('embedded-opentype'),
    url(/static/media/icons.18948bfa.ttf) format('truetype'),
    url(/static/media/icons.f094d7d4.woff) format('woff'),
    url(/static/media/icons.73d323ac.svg#icons) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Android-101";
  src: url(/static/media/Android-101.af53130e.otf) format("otf"),
    url(/static/media/Android-101.1bc6db32.ttf) format("truetype");
}

[class^="custom-icon-"], [class*=" custom-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-icon-facebook:before {
  content: "\e900";
  color: #9f9f9f;
}
.custom-icon-github:before {
  content: "\e901";
  color: #9f9f9f;
}
.custom-icon-telegram:before {
  content: "\e902";
  color: #d01f36;
}
.custom-icon-twitter:before {
  content: "\e903";
  color: #9f9f9f;
}

body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  font-size: 14px;
  margin: 0px;
}

main {
  background-color: #F7F8FA;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

a,
a:hover,
a:active {
  text-decoration: none;
  color: #4A4A4A;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

ol,
ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input[type=text][disabled], input:disabled {
  cursor: not-allowed;
}

.text-danger {
  color: red;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

input[type=number] {
  -moz-appearance: textfield,
}
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:disabled {
  /* khong important file commont */
  /* background-color: #5d5d5d !important; */
  /* border-color: #5d5d5d !important; */
  background-color: #5d5d5d;
  border-color: #5d5d5d;
  cursor: not-allowed ;
}

.collapse-css-transition {
  transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

